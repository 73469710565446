<template>
  <div class="row">

    <div class="col-md-12">
      <Card :title="'Tambah Donor'">
        <template v-slot:body>
          <Form
            :form="form"
            :route="'donors'"
          />
        </template>
      </Card>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Form from '@/component/donors/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      form: {
        name: "",
        blood_type_id: null,
        age: null,
        birt_date: new Date().toISOString().substr(0, 10),
        last_donation_date: new Date().toISOString().substr(0, 10),
        phone: "",
        gender: null,
        quantity: 1,
        email: "",
        id_card_number: null,
        address: "",
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bank Darah", route: "/blood-bank" },
      { title: "Daftar Donor", route: "/donors" },
      { title: "Tambah Donor" },
    ])
  },

}
</script>

<style>
</style>